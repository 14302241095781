


































import { SocialpostTopic } from "@/models/socialpost-topic.model";
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";

const socialpostTopicsModule = namespace("SocialpostTopics");
const languagesModule = namespace("Languages");

@Component({ components: {} })
export default class SocialpostTopicDetail extends Vue {
  isLoading = false;

  @languagesModule.Getter("all")
  languages!: string[];

  @socialpostTopicsModule.Getter("current")
  socialpostTopic!: SocialpostTopic;

  @socialpostTopicsModule.Action("fetchById")
  fetchTopic!: (id: string) => Promise<SocialpostTopic>;

  @socialpostTopicsModule.Action("create")
  create!: (socialpostTopic: SocialpostTopic) => Promise<SocialpostTopic>;

  @socialpostTopicsModule.Action("update")
  update!: (socialpostTopic: SocialpostTopic) => Promise<SocialpostTopic>;

  get isNewSocialpostTopic() {
    return !this.$route.params.id;
  }

  get url() {
    return `${this.baseUrl}social/${this.id}`;
  }

  get id() {
    return this.$route.params.id;
  }

  get baseUrl() {
    return process.env.VUE_APP_BASE_URL;
  }

  async doSave() {
    this.isLoading = true;
    if (this.isNewSocialpostTopic) {
      await this.create(this.socialpostTopic);
      this.$router.push({
        name: "socialpost-topics-detail",
        params: { id: this.socialpostTopic.id },
      });
    } else {
      await this.update(this.socialpostTopic);
    }
    this.isLoading = false;
  }

  async doFetchSocialpostTopic() {
    this.isLoading = true;
    if (!this.isNewSocialpostTopic) {
      await this.fetchTopic(this.$route.params.id);
    }
    this.isLoading = false;
  }

  created() {
    this.doFetchSocialpostTopic();
  }
}
